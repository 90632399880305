import { css } from '@emotion/react';

import mq from './mediaQueries';

export const FONT_FAMILIES = {
  Inter: {
    thin: '100',
    lightest: '200',
    light: '300',
    regular: '400',
    lightBold: '500',
    semiBold: '600',
    bold: '700',
    veryBold: '800',
    black: '900',
  },
} as const;

const typography = {
  h1: css`
    font-size: 11.5rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 5rem;
    }
  `,
  h1a: css`
    font-size: 10rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 4rem;
    }
  `,
  h2: css`
    font-size: 7rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 4rem;
    }
  `,
  h3: css`
    font-size: 5.625rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 4rem;
    }
  `,
  h4: css`
    ${mq.md.up} {
      font-size: 4rem;
      line-height: 1.25;
    }

    font-size: 3rem;
  `,
  p1: css`
    font-size: 2.25rem;
    line-height: 1.78;

    ${mq.md.down} {
      font-size: 1.75rem;
      line-height: 1.715;
    }
  `,
  p2: css`
    font-size: 2rem;
    line-height: 1.5;

    ${mq.md.down} {
      font-size: 1.75rem;
      line-height: 1.715;
    }
  `,
  p3: css`
    font-size: 2rem;
    line-height: 1.75;

    ${mq.md.down} {
      font-size: 1.75rem;
      line-height: 1.715;
    }
  `,
};

export const webTypography = {
  h1: css`
    font-size: 15rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 5rem;
    }
  `,
  h2: css`
    font-size: 7.5rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 3rem;
    }
  `,
  h3: css`
    font-size: 7rem;
    font-weight: ${FONT_FAMILIES.Inter.bold};

    ${mq.md.down} {
      font-size: 3rem;
    }
  `,
  h4: css`
    font-size: 3rem;
    font-weight: ${FONT_FAMILIES.Inter.lightBold};
    line-height: 1.1;

    ${mq.md.down} {
      font-size: 2rem;
    }
  `,
  h5: css`
    font-size: 2.5rem;
    line-height: 1.6;

    ${mq.md.down} {
      font-size: 1.75rem;
      line-height: 1.715;
    }
  `,
  p1: css`
    font-size: 3rem;
    line-height: 1.67;

    ${mq.md.down} {
      font-size: 2.25rem;
      line-height: 1.78;
    }
  `,
  p2: css`
    font-size: 3rem;
    line-height: 1.5;

    ${mq.md.down} {
      font-size: 1.75rem;
      line-height: 1.715;
    }
  `,
};

export default typography;
